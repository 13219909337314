import React, { useState, useEffect } from "react"
import Hero from "./components/Hero"
import About from "./components/About"
import Portfolio from "./components/Portfolio"
import Contact from "./components/Contact"
import AlternateNavbar from "./components/AlternateNavbar"
import Footer from "./components/Footer"
import Loading from "./components/Loading"

function App() {
  const [showAlternateNavbar, setShowAlternateNavbar] = useState(false)
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    // Simulate loading time (adjust duration as needed)
    const loadingTimeout = setTimeout(() => {
      setLoading(false)
    }, 2500) // 5000 milliseconds (5 seconds) for example

    // Clean up the timeout to avoid memory leaks
    return () => clearTimeout(loadingTimeout)
  }, [])

  useEffect(() => {
    const handleScroll = () => {
      const aboutSection = document.getElementById("about")
      const { top } = aboutSection.getBoundingClientRect()
      const isVisible = top <= 0

      setShowAlternateNavbar(isVisible)
    }

    window.addEventListener("scroll", handleScroll)

    return () => {
      window.removeEventListener("scroll", handleScroll)
    }
  }, [])

  return (
    <div>
      {loading ? (
        <Loading />
      ) : (
        <>
          <Hero />
          <AlternateNavbar isVisible={showAlternateNavbar} />
          <About />
          <Portfolio />
          <Contact />
          <Footer />
        </>
      )}
    </div>
  )
}

export default App

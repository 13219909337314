import React from "react"
import logo1 from "./assets/logo-a.svg"

import "../styles/loading.scss"

function Loading() {
  return (
    <div className="body">
      <div className="content">
        <div className="loader-component">
          <img className="logo" src={logo1} />
        </div>
      </div>
    </div>
  )
}

export default Loading

import React, { useRef, useEffect } from "react"
import portfolio1 from "../components/assets/portfolio4.png"
import portfolio2 from "../components/assets/portfolio5.png"
import portfolio3 from "../components/assets/portfolio6.png"
import github from "../components/assets/github.svg"
import web from "../components/assets/web.svg"
import classes from "../styles/components/PortfolioList.module.scss"

function PortfolioPersonalList() {
  const portfolioRef = useRef()

  useEffect(() => {
    const handleScroll = () => {
      const portfolioSection = portfolioRef.current.getBoundingClientRect()
      const windowHeight = window.innerHeight

      if (portfolioSection.top < windowHeight && portfolioSection.bottom >= 1) {
        portfolioRef.current.classList.add(classes.visible)
      }
    }

    window.addEventListener("scroll", handleScroll)

    return () => {
      window.removeEventListener("scroll", handleScroll)
    }
  }, [])

  return (
    <div
      className={`pt-2 ${classes.portfolioContainer} ${classes.fadeIn}`}
      ref={portfolioRef}
    >
      <div className="row">
        {/* First Card */}
        <div
          className={`col-sm-12 col-md-6 col-lg-4 mb-4  ${classes.portfolioCard}`}
        >
          <div className={classes.boxImg}>
            <img className={`w-100 ${classes.portfolioImg}`} src={portfolio1} />
            <div className={classes.overlay}>
              <a
                href="https://github.com/robertmartinsen/Universal-Traders-Academy"
                target="_blank"
              >
                <div className={`${classes.iconBox} ${classes.githubIcon}`}>
                  <img
                    className={`${classes.githubIcon} ${classes.circle}`}
                    src={github}
                    alt="Github"
                  />
                </div>
              </a>
              <a
                className={classes.imgLink}
                href="https://chipper-sundae-2b0d28.netlify.app/"
                target="_blank"
              >
                <div className={`${classes.iconBox} ${classes.webIcon}`}>
                  <img
                    className={`${classes.webIcon} ${classes.circle}`}
                    src={web}
                    alt="Web"
                  />
                </div>
              </a>
              <div className={classes.texts}>
                <h3 className={classes.title}>Universal Traders Academy</h3>
                <p className={classes.description}>[React, SCSS, Bootstrap]</p>
              </div>
            </div>
          </div>
        </div>
        {/* Second Card */}
        <div
          className={`col-sm-12 col-md-6 col-lg-4 mb-4 ${classes.portfolioCard}`}
        >
          <div className={classes.boxImg}>
            <img className={`w-100 ${classes.portfolioImg}`} src={portfolio2} />
            <div className={classes.overlay}>
              <a
                href="https://github.com/robertmartinsen/bettersitechoice"
                target="_blank"
              >
                <div className={`${classes.iconBox} ${classes.githubIcon}`}>
                  <img
                    className={`${classes.githubIcon} ${classes.circle}`}
                    src={github}
                    alt="Github"
                  />
                </div>
              </a>
              <a
                className={classes.imgLink}
                href="https://bestsitechoice.com/"
                target="_blank"
              >
                <div className={`${classes.iconBox} ${classes.webIcon}`}>
                  <img
                    className={`${classes.webIcon} ${classes.circle}`}
                    src={web}
                    alt="Web"
                  />
                </div>
              </a>
              <div className={classes.texts}>
                <h3 className={classes.title}>Best Site Choice</h3>
                <p className={classes.description}>[HTML, SCSS, Javascript]</p>
              </div>
            </div>
          </div>
        </div>
        {/* Third Card */}
        <div
          className={`col-sm-12 col-md-6 col-lg-4 mb-4 ${classes.portfolioCard}`}
        >
          <div className={classes.boxImg}>
            <img className={`w-100 ${classes.portfolioImg}`} src={portfolio3} />
            <div className={classes.overlay}>
              <a
                href="https://github.com/robertmartinsen/weather-app"
                target="_blank"
              >
                <div className={`${classes.iconBox} ${classes.githubIcon}`}>
                  <img
                    className={`${classes.githubIcon} ${classes.circle}`}
                    src={github}
                    alt="Github"
                  />
                </div>
              </a>
              <a
                className={classes.imgLink}
                href="https://unrivaled-pony-1ed97d.netlify.app/"
                target="_blank"
              >
                <div className={`${classes.iconBox} ${classes.webIcon}`}>
                  <img
                    className={`${classes.webIcon} ${classes.circle}`}
                    src={web}
                    alt="Web"
                  />
                </div>
              </a>
              <div className={classes.texts}>
                <div className={classes.textContent}>
                  <h3 className={classes.title}>Weather App</h3>
                  <p className={classes.description}>
                    [HTML, CSS, Javascript]
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default PortfolioPersonalList

import React from "react"
import classes from "../styles/components/Footer.module.scss"
import logo from "./assets/logo.svg"

import instagram from "./assets/instagram.svg"
import github from "./assets/github1.svg"
import gmail from "./assets/gmail.svg"
import discord from "./assets/discord.svg"

function Footer() {
  return (
    <footer className={`${classes.footer} text-light text-center py-3 pb-5`}>
      <div className="container text-center">
        <div className={`mt-4 ${classes.iconContainer}`}>
          <a href="https://www.instagram.com/robertmartinsen_/" target="_blank">
            <div className={classes.iconBg}>
              <img src={instagram} className={classes.icon} />
            </div>
          </a>
          <a href="https://github.com/robertmartinsen" target="_blank">
            <div className={classes.iconBg}>
              <img src={github} className={classes.icon} />
            </div>
          </a>
          <a target="_blank" href="mailto: robertmartinsen2000@gmail.com">
            <div className={classes.iconBg}>
              <img src={gmail} className={classes.icon} />
            </div>
          </a>
          <a target="_blank" href="https://www.discordapp.com/users/6005">
            <div className={classes.iconBg}>
              <img src={discord} className={classes.icon} />
            </div>
          </a>
        </div>
        <img src={logo} className="pt-4" />{" "}
        <p className="pt-3">
          &copy; {new Date().getFullYear()}. All rights reserved.
        </p>
      </div>
    </footer>
  )
}

export default Footer
